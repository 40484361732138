/* tslint:disable */
/* eslint-disable */
/**
 * Usan - Saas Registration - API Gateway 3.0
 * This is an API Gateway to provide a list of lambda functions that Saas Registration will use.
 *
 * OpenAPI spec version: 1.0.13
 * Contact: example@usan.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, {
  AxiosResponse,
  AxiosInstance,
  AxiosRequestConfig,
} from "axios";
import { Configuration } from "../configuration";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from "../base";
import { ErrorMessageResponse } from "../models";
import { Registration } from "../models";
/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Receives orders from the marketplace registration page
     * @param {string} version API version
     * @param {Registration} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handleMarketplaceOrder: async (
      version: string,
      body?: Registration,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'version' is not null or undefined
      if (version === null || version === undefined) {
        throw new RequiredError(
          "version",
          "Required parameter version was null or undefined when calling handleMarketplaceOrder.",
        );
      }
      const localVarPath = `/v{version}/handle-marketplace-order`.replace(
        `{${"version"}}`,
        encodeURIComponent(String(version)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions: AxiosRequestConfig = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new URLSearchParams();

      localVarHeaderParameter["Content-Type"] = "application/json";

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.params) {
        query.set(key, options.params[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof body !== "string" ||
        (localVarRequestOptions.headers &&
          localVarRequestOptions.headers["Content-Type"] ===
            "application/json");
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(body !== undefined ? body : {})
        : body || "";

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Receives orders from the marketplace registration page
     * @param {string} version API version
     * @param {string} [registrationToken]
     * @param {string} [tenantSubdomain]
     * @param {string} [subscriptionSubdomain]
     * @param {string} [subscriptionName]
     * @param {string} [tenantId]
     * @param {string} [companyName]
     * @param {string} [companyAddress]
     * @param {string} [companyAddress2]
     * @param {string} [companyCity]
     * @param {string} [companyState]
     * @param {string} [companyZip]
     * @param {string} [userEmail]
     * @param {string} [userName]
     * @param {string} [userPhone]
     * @param {Array<string>} [errors]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handleMarketplaceOrderForm: async (
      version: string,
      registrationToken?: string,
      tenantSubdomain?: string,
      subscriptionSubdomain?: string,
      subscriptionName?: string,
      tenantId?: string,
      companyName?: string,
      companyAddress?: string,
      companyAddress2?: string,
      companyCity?: string,
      companyState?: string,
      companyZip?: string,
      userEmail?: string,
      userName?: string,
      userPhone?: string,
      errors?: Array<string>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'version' is not null or undefined
      if (version === null || version === undefined) {
        throw new RequiredError(
          "version",
          "Required parameter version was null or undefined when calling handleMarketplaceOrderForm.",
        );
      }
      const localVarPath = `/v{version}/handle-marketplace-order`.replace(
        `{${"version"}}`,
        encodeURIComponent(String(version)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions: AxiosRequestConfig = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new URLSearchParams();

      if (registrationToken !== undefined) {
        localVarFormParams.set("registrationToken", registrationToken as any);
      }

      if (tenantSubdomain !== undefined) {
        localVarFormParams.set("tenantSubdomain", tenantSubdomain as any);
      }

      if (subscriptionSubdomain !== undefined) {
        localVarFormParams.set(
          "subscriptionSubdomain",
          subscriptionSubdomain as any,
        );
      }

      if (subscriptionName !== undefined) {
        localVarFormParams.set("subscriptionName", subscriptionName as any);
      }

      if (tenantId !== undefined) {
        localVarFormParams.set("tenantId", tenantId as any);
      }

      if (companyName !== undefined) {
        localVarFormParams.set("companyName", companyName as any);
      }

      if (companyAddress !== undefined) {
        localVarFormParams.set("companyAddress", companyAddress as any);
      }

      if (companyAddress2 !== undefined) {
        localVarFormParams.set("companyAddress2", companyAddress2 as any);
      }

      if (companyCity !== undefined) {
        localVarFormParams.set("companyCity", companyCity as any);
      }

      if (companyState !== undefined) {
        localVarFormParams.set("companyState", companyState as any);
      }

      if (companyZip !== undefined) {
        localVarFormParams.set("companyZip", companyZip as any);
      }

      if (userEmail !== undefined) {
        localVarFormParams.set("userEmail", userEmail as any);
      }

      if (userName !== undefined) {
        localVarFormParams.set("userName", userName as any);
      }

      if (userPhone !== undefined) {
        localVarFormParams.set("userPhone", userPhone as any);
      }
      if (errors) {
        errors.forEach((element) => {
          localVarFormParams.append("errors", element as any);
        });
      }
      localVarHeaderParameter["Content-Type"] =
        "application/x-www-form-urlencoded";
      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.params) {
        query.set(key, options.params[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams.toString();

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Receives orders from the marketplace registration page
     * @param {string} version API version
     * @param {Registration} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async handleMarketplaceOrder(
      version: string,
      body?: Registration,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration,
      ).handleMarketplaceOrder(version, body, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH,
      ) => {
        const axiosRequestArgs: AxiosRequestConfig = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Receives orders from the marketplace registration page
     * @param {string} version API version
     * @param {string} [registrationToken]
     * @param {string} [tenantSubdomain]
     * @param {string} [subscriptionSubdomain]
     * @param {string} [subscriptionName]
     * @param {string} [tenantId]
     * @param {string} [companyName]
     * @param {string} [companyAddress]
     * @param {string} [companyAddress2]
     * @param {string} [companyCity]
     * @param {string} [companyState]
     * @param {string} [companyZip]
     * @param {string} [userEmail]
     * @param {string} [userName]
     * @param {string} [userPhone]
     * @param {Array<string>} [errors]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async handleMarketplaceOrderForm(
      version: string,
      registrationToken?: string,
      tenantSubdomain?: string,
      subscriptionSubdomain?: string,
      subscriptionName?: string,
      tenantId?: string,
      companyName?: string,
      companyAddress?: string,
      companyAddress2?: string,
      companyCity?: string,
      companyState?: string,
      companyZip?: string,
      userEmail?: string,
      userName?: string,
      userPhone?: string,
      errors?: Array<string>,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>
    > {
      const localVarAxiosArgs = await DefaultApiAxiosParamCreator(
        configuration,
      ).handleMarketplaceOrderForm(
        version,
        registrationToken,
        tenantSubdomain,
        subscriptionSubdomain,
        subscriptionName,
        tenantId,
        companyName,
        companyAddress,
        companyAddress2,
        companyCity,
        companyState,
        companyZip,
        userEmail,
        userName,
        userPhone,
        errors,
        options,
      );
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH,
      ) => {
        const axiosRequestArgs: AxiosRequestConfig = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  return {
    /**
     *
     * @summary Receives orders from the marketplace registration page
     * @param {string} version API version
     * @param {Registration} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async handleMarketplaceOrder(
      version: string,
      body?: Registration,
      options?: AxiosRequestConfig,
    ): Promise<AxiosResponse<void>> {
      return DefaultApiFp(configuration)
        .handleMarketplaceOrder(version, body, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Receives orders from the marketplace registration page
     * @param {string} version API version
     * @param {string} [registrationToken]
     * @param {string} [tenantSubdomain]
     * @param {string} [subscriptionSubdomain]
     * @param {string} [subscriptionName]
     * @param {string} [tenantId]
     * @param {string} [companyName]
     * @param {string} [companyAddress]
     * @param {string} [companyAddress2]
     * @param {string} [companyCity]
     * @param {string} [companyState]
     * @param {string} [companyZip]
     * @param {string} [userEmail]
     * @param {string} [userName]
     * @param {string} [userPhone]
     * @param {Array<string>} [errors]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async handleMarketplaceOrderForm(
      version: string,
      registrationToken?: string,
      tenantSubdomain?: string,
      subscriptionSubdomain?: string,
      subscriptionName?: string,
      tenantId?: string,
      companyName?: string,
      companyAddress?: string,
      companyAddress2?: string,
      companyCity?: string,
      companyState?: string,
      companyZip?: string,
      userEmail?: string,
      userName?: string,
      userPhone?: string,
      errors?: Array<string>,
      options?: AxiosRequestConfig,
    ): Promise<AxiosResponse<void>> {
      return DefaultApiFp(configuration)
        .handleMarketplaceOrderForm(
          version,
          registrationToken,
          tenantSubdomain,
          subscriptionSubdomain,
          subscriptionName,
          tenantId,
          companyName,
          companyAddress,
          companyAddress2,
          companyCity,
          companyState,
          companyZip,
          userEmail,
          userName,
          userPhone,
          errors,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
  /**
   *
   * @summary Receives orders from the marketplace registration page
   * @param {string} version API version
   * @param {Registration} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public async handleMarketplaceOrder(
    version: string,
    body?: Registration,
    options?: AxiosRequestConfig,
  ): Promise<AxiosResponse<void>> {
    return DefaultApiFp(this.configuration)
      .handleMarketplaceOrder(version, body, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Receives orders from the marketplace registration page
   * @param {string} version API version
   * @param {string} [registrationToken]
   * @param {string} [tenantSubdomain]
   * @param {string} [subscriptionSubdomain]
   * @param {string} [subscriptionName]
   * @param {string} [tenantId]
   * @param {string} [companyName]
   * @param {string} [companyAddress]
   * @param {string} [companyAddress2]
   * @param {string} [companyCity]
   * @param {string} [companyState]
   * @param {string} [companyZip]
   * @param {string} [userEmail]
   * @param {string} [userName]
   * @param {string} [userPhone]
   * @param {Array<string>} [errors]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public async handleMarketplaceOrderForm(
    version: string,
    registrationToken?: string,
    tenantSubdomain?: string,
    subscriptionSubdomain?: string,
    subscriptionName?: string,
    tenantId?: string,
    companyName?: string,
    companyAddress?: string,
    companyAddress2?: string,
    companyCity?: string,
    companyState?: string,
    companyZip?: string,
    userEmail?: string,
    userName?: string,
    userPhone?: string,
    errors?: Array<string>,
    options?: AxiosRequestConfig,
  ): Promise<AxiosResponse<void>> {
    return DefaultApiFp(this.configuration)
      .handleMarketplaceOrderForm(
        version,
        registrationToken,
        tenantSubdomain,
        subscriptionSubdomain,
        subscriptionName,
        tenantId,
        companyName,
        companyAddress,
        companyAddress2,
        companyCity,
        companyState,
        companyZip,
        userEmail,
        userName,
        userPhone,
        errors,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}
