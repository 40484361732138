import { DefaultApi } from "./apis/default-api";

export default class Api {
  public defaultApi: DefaultApi;

  constructor() {
    const { REACT_APP_AWSGATEWAYAPI_BASE_URL } = process.env;
    let APIGATEWAY_ENDPOINT: string = "";

    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
      APIGATEWAY_ENDPOINT =
        "https://stoplight.io/mocks/borderless/usan-portal/151550350";
    } else {
      APIGATEWAY_ENDPOINT = REACT_APP_AWSGATEWAYAPI_BASE_URL ?? "";
    }

    this.defaultApi = new DefaultApi({ basePath: APIGATEWAY_ENDPOINT });
  }
}
